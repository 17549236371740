
const OrderInformation = (props) => {

  const Order = (props) => {
    try {
      const orderParts = props.data.split(' || ');

      let prettyString = orderParts[0] + ' ';
      if (orderParts[0] === '1') {
        prettyString += 'copy purchased for ';
      } else {
        prettyString += 'copies purchased for ';
      }
      prettyString += orderParts[1] + ' on ' + orderParts[2];

      return (
        <div className="individual-order-listing">{prettyString}</div>
      )
    } catch {}
  }

  const Orders = (props) => {
    try {
      return props.data.sm_orders.map(item => <Order key={item} data={item}></Order>)
    } catch {}
  }

  const LACMessage = (props) => {
    try {
      let isOneLACOrder = false
      let isAllLACOrders = true

      for (let i = 0; i < props.data.sm_orders.length; i++) {
        if (props.data.sm_orders[i].indexOf('Library for Arts') > -1) {
          isOneLACOrder = true;
        } else {
          isAllLACOrders = false;
        }
      }

      if (isOneLACOrder && isAllLACOrders) {
        return (
          <div className="bold margin-top-10">To check out this item when it becomes available, visit the Library for Arts & Culture</div>
        )
      }
    } catch {}
  }

  try {
    if (props.data.bs_on_order === true) {
      if (props.data.sm_orders.length > 0) {
        return (
          <div className="order-information">
            <h3>Orders</h3>
            <Orders data={props.data}></Orders>
            <LACMessage data={props.data}/>
          </div>
        )
      }
    }
  } catch {}
};

export default OrderInformation;