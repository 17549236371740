import { useState } from "react";
import postData from "../../functions/postData";
import configs from "../../functions/configs";
import IconRobot from "../icons/iconRobot";
import onLoadImageHandler from "../../functions/onLoadImageHandler";
import fallbackImageHandler from "../../functions/fallbackImageHandler";
import IconEllipsis from "../icons/iconEllipsis";
import toggleOverlay from "../../functions/toggleOverlay";
import setCookie from "../../functions/setCookie";
import MagnifyingGlassIcon from "../icons/magnifyingGlassIcon";
import PlusSign from "../icons/plusSign";
import getData from "../../functions/getData";
import getCookie from "../../functions/getCookie";
import deleteCookie from "../../functions/deleteCookie";

let dataGrabbed = false;
const lists = {};

const BooklistsContainer = (props) => {

    const conf = configs();

    const [listState, setListState] = useState('');

    const [sortState, setSortState] = useState('');

    const [searchState, setSearchState] = useState('');


    let switchedAccounts = getCookie('bplswitchedbooklists');

    if (switchedAccounts !== '') {
        delete lists.data;
    }

    if (dataGrabbed === false || switchedAccounts !== '') {
        getData(conf.baseAPI + "/user/return-lists.php").then((res) => {
            console.log(res);
            lists.data = res;
            if (switchedAccounts !== '') {
                setListState('loaded' + Date.now());
            } else {
                setListState('loaded');
            }
            dataGrabbed = true;
            deleteCookie('bplswitchedbooklists');
        });
    }

    if (listState === '' && sortState === '') {
        try {
            // unset filters and sort if returning to screen after initial load
            console.log('listState', listState); 
            lists.data.sort((a, b) => {
                if (a.changed < b.changed) {
                    return 1;
                } else if (a.changed > b.changed) {
                    return -1
                } else {
                    return 0;
                }
            });
        } catch {}
    }

    const launchEditList = (e) => {
        try {
            setCookie('editListId', e.currentTarget.attributes.listid.value);
            document.querySelector('#update-edit-list-overlay-data').click();
            toggleOverlay('#edit-list-overlay');
        } catch {}
    }

    const launchEditListItems = (e) => {
        try {
            setCookie('editListItemsId', e.currentTarget.attributes.listid.value);
            document.querySelector('#update-edit-list-items-overlay-data').click();
            toggleOverlay('#edit-list-items-overlay');
        } catch {}      
    }

    const List = (props) => {
        try {

            let listLink = '/?booklist=' + props.data.booklist_id;

            if (props.data.slug !== '') {
                listLink = '/?booklist=' + props.data.slug;
            }

            let count = props.data.items.length + ' items';
            if (props.data.items.length === 1) {
                count = '1 item';
            }

            let listImg = '';

            try {

                const firstItem = JSON.parse(props.data.items[0].item_value);
                listImg = firstItem.img;

            } catch {}

            const Privacy = (props) => {

                try {

                    if (props.data === 'private') {
                        return (
                            <div>
                                <span className="bold">Private</span> (cannot be viewed by others)
                            </div>
                        )
                    } else if (props.data === 'public') {
                        return (
                            <div>
                                <span className="bold">Public</span> (can be viewed by others)
                            </div>
                        )
                    }

                } catch {}
                
            }

            const showMoreActions = (e) => {
                try {
                    if (e.currentTarget.nextSibling.classList.contains('hide')) {
                        e.currentTarget.nextSibling.classList.remove('hide');
                    } else {
                        e.currentTarget.nextSibling.classList.add('hide');
                    }
                } catch (err) {console.log(err)}
            }

            const launchDeleteList = (e) => {
                try {
                    setCookie('deleteListId', e.currentTarget.attributes.listid.value);
                    toggleOverlay('#delete-list-overlay');
                } catch {}
            }
            
            let classes = "my-account-list";

            if (searchState !== '') {
                try {
                    if (props.data.title.toLowerCase().indexOf(searchState.toLowerCase()) === -1) {
                        classes += ' hide'
                    }
                } catch {}
            }

            const darkRed = {color: "darkred"};

            return (
                <div created={props.data.created} changed={props.data.changed} className={classes}>
                    <div className="flex-small-gap">
                        <div className="account-list-img-container">
                            <img onLoad={onLoadImageHandler} onError={fallbackImageHandler} src={listImg}></img>
                        </div>
                        <div className="account-list-text-container">
                            <div className="my-account-list-title"><a className="my-account-list-title-link" href={listLink}>{props.data.title}</a></div>
                            <div className="dark-gray my-account-list-count">{count}</div>
                            <div className="my-account-list-description">{props.data.description}</div>
                            <Privacy data={props.data.privacy}/>
                        </div>
                    </div>
                    <div className="account-list-actions">
                        <button onClick={launchEditList} listid={props.data.booklist_id} className="default-white-button">List settings</button>
                        <button onClick={launchEditListItems} listid={props.data.booklist_id} className="default-white-button">Edit Items</button>

                        <div className="booklist-more-actions-container">
                            <button onClick={showMoreActions} title="More actions" className="booklist-more-actions-btn"><IconEllipsis height="30"></IconEllipsis><span className='sr-only'>More actions</span></button>
                            <div className="more-actions-dropdown-container hide">
                                <div className="more-actions-dropdown">
                                    <button onClick={()=>{window.location.href = '/api/misc/list-pdf-export.php?id=' + props.data.booklist_id;}} className="list-export-btn main-color" listid={props.data.booklist_id}>Export PDF</button>
                                    <button style={darkRed} onClick={launchDeleteList} className="delete-list-btn" listid={props.data.booklist_id}>Delete List</button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            )
        } catch {}
    }

    const Lists = (props) => {
        try {
            if (lists.data.length === 0) {
                return (
                    <div className="my-account-catalog-no-results">You have no lists!</div>
                )
            }
    
            if (lists.data.length > 0) {
                return lists.data.map((item)=><List key={item.id} data={item}></List>);
            }
        } catch {
            return (
                <div>Beep Boop. Gathering data... &nbsp; <IconRobot></IconRobot></div>
            )
        }
    }

    const updateBooklistState = (e) => {
        dataGrabbed = false;
        setListState('edited ' + Date.now());
    }
    
    let sortVal = 'updated';

    const sortLists = (e) => {

        try {

            if (e.currentTarget.value === 'title') {
                lists.data.sort((a, b) => {
                    if (a.title > b.title) {
                        return 1;
                    } else if (a.title < b.title) {
                        return -1
                    } else {
                        return 0;
                    }
                });

                setSortState('title');
            }

            if (e.currentTarget.value === 'updated') {
                lists.data.sort((a, b) => {
                    if (a.changed < b.changed) {
                        return 1;
                    } else if (a.changed > b.changed) {
                        return -1
                    } else {
                        return 0;
                    }
                });
                setSortState('updatedSort');
            }

        } catch {}
        
    }

    const launchCreateListOverlay = (e) => {
        toggleOverlay('#create-new-list-overlay');
    }

    const CreateNewListButton = (props) => {
        if (dataGrabbed) {
            return (
                <button onClick={launchCreateListOverlay} id="create-new-list-my-account"><PlusSign></PlusSign><span className="underline">Create new list</span></button>
            )
        }
    }

    let topTilesClasses = "account-top-tiles-container hide";

    try {
        if (lists.data.length > 1) {
            topTilesClasses = "account-top-tiles-container";
        }
    } catch {}

    return (
        <div className="my-account-content-container">
            
            <div className={topTilesClasses}>
                <div className="account-sort-fiter-container">
                    
                    <div className="search-list-input-container">
                        <label for='search-lists' className="sr-only">Search by list title</label>
                        <input type="text" autoComplete="off" onChange={(e) => setSearchState(e.currentTarget.value)} id="search-lists" placeholder="Search by list title"></input>
                        <MagnifyingGlassIcon height="20" fillColor="#484E80"></MagnifyingGlassIcon>
                    </div>
                    

                    <label for="lists-sort" className="sr-only">Sort lists</label>
                    <select onChange={sortLists} defaultValue={sortVal} id="lists-sort">
                        <option value="updated">Sort by recently updated</option>
                        <option value="title">Sort by list title</option>
                    </select>

                </div>
            </div>

            <CreateNewListButton></CreateNewListButton>

            <div className="my-account-lists-container">
                <Lists></Lists>
            </div>

            <button onClick={updateBooklistState} id="update-my-account-booklists-state" className="hide"></button>
            
        </div>
    )
}

export default BooklistsContainer;