import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import postData from "../../functions/postData";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import configs from "../../functions/configs";
import { useContext, useState } from "react";
import { UserContext } from "../..";
import detectUserAgent from "../../functions/detectUserAgent";
import clearCache from "../../functions/clearCache";

const EditAddressOverlay = (props) => {

    const conf = configs();

    const user = useContext(UserContext);

    const updateAddress = (e) => {

        e.preventDefault();

        let addressFormatted = '';

        let street = document.querySelector("#address-street").value.toUpperCase();
        let city = document.querySelector("#address-city").value.toUpperCase();
        let state = document.querySelector("#address-state").value.toUpperCase();
        let zip = document.querySelector("#address-zip").value.toUpperCase();

        addressFormatted = street + "$" + city + " " + state + " " + zip;

        let postString = 'address=' + encodeURIComponent(addressFormatted);
        postString += '&agent=' + detectUserAgent();

        toggleLoadingOverlay();

        postData(conf.baseAPI + "/sierra/sierra-update-address.php", postString).then((res) => {

            console.log(res);

            toggleLoadingOverlay();
            
            if (res === null) { // success
                document.querySelector('#update-state-my-account-edit-container').click();
                launchSuccessOverlay("Address updated", "");
                document.querySelector('#edit-address-overlay button.close-overlay').click();
                clearCache();
            } else if (typeof res.sessionExpired !== 'undefined') {
                document.querySelector('#edit-address-overlay button.close-overlay').click();
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                document.querySelector('#edit-address-overlay button.close-overlay').click();
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                document.querySelector('#edit-address-overlay button.close-overlay').click();
                launchErrorOverlay(res.message, '');
            }
        });
    }

    const closeOverlay = (e) => {
        toggleOverlay("#edit-address-overlay");
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    const marginTop = {marginTop: '15px'};

    let defaultStreet = '';
    let defaultCity = '';
    let defaultState = '';
    let defaultZip = '';

    try {
        const addressParts = user.data.session.bpl_catalog.address.split('$');
        defaultStreet = addressParts[0];
        defaultZip = addressParts[1].match(/\d\d\d\d\d/)[0];
        let almostState = addressParts[1].replace(defaultZip, '');
        defaultState = almostState.trim().match(/ ..$/)[0].trim();
        defaultCity = addressParts[1].replace(defaultZip, '');
        defaultCity = defaultCity.replace(defaultState, '');
        defaultCity = defaultCity.trim();
    } catch {}

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="edit-address-overlay" className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Edit Address</h3>

                <form onSubmit={updateAddress} id="update-address-form">
                    <div>
                        <div style={marginTop}>
                            <label htmlFor="address-street">Street Address</label>
                            <input width="200" defaultValue={defaultStreet} required id="address-street" type="text"></input>
                        </div>

                        <div style={marginTop} className="flex wrap">
                            <div>
                                <label htmlFor="address-city">City</label>
                                <input width="100" defaultValue={defaultCity} required id="address-city" type="text"></input>
                            </div>
                            <div>
                                <label htmlFor="address-state">State</label>
                                <input width="20" defaultValue={defaultState} required id="address-state" maxLength="2" type="text"></input>
                            </div>
                            <div>
                                <label htmlFor="address-zip">Zip</label>
                                <input width="40" defaultValue={defaultZip} required id="address-zip" maxLength="5" type="text"></input>
                            </div>
                        </div>

                        <div>
                            <button type="submit">Update</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
} 

export default EditAddressOverlay;